.auth-bg {
    background-color: #800000; /* Maroon background */
  }
  
  .auth-container {
    background-color: #ffffff; /* White background for the form */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }
  
  button.btn-light {
    background-color: #800000; /* Maroon button */
    color: #ffffff;
  }
  
  button.btn-light:hover {
    background-color: #5a0000; /* Darker maroon on hover */
  }
  
  button.btn-link {
    color: #ffffff;
    font-weight: bold;
  }
  
  button.btn-link:hover {
    color: #cccccc;
  }
  
  .form-control {
    background-color: #f8f9fa; /* Light grey for input fields */
  }
  