.rotate-device-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .rotate-device-message {
    color: #fff;
    text-align: center;
    font-size: 34px;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .rotate-device-message2 {
    color: #fff;
    position: relative;
    margin-bottom: 2400px;
    margin-right: 1400px;
    font-size: 30px;
    top:5px;
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.9);
  }