.sticky1{
position: absolute;
width: 10px;
height: 10px;
z-index: 1;

}
.sticky2{
    position: fixed;
    width: 1000px;
    height: 1000px;
    top:-500px;
    z-index: 1;
    left:300px;
    position: 50px;
    
    }
    .mainn{
        background-color: #7AC7F8;
        height: 100vh; /* Ensure it covers the full viewport height */
        width: 100vw;  /* Ensure it covers the full viewport width */
        margin: 0;     /* Remove any default margin */
        padding: 0;    /* Remove any default padding */
        overflow: hidden; /* Prevent scrolling, if necessary */    }
    @media  (max-width: 1000px)   {
        .mainn{
            position: relative !important;
            width: 100vw !important;
            height: 100vh  !important;
            left: -610px  !important;
        }
    
    }


@media  (max-width: 1000px)   {

    .sticky2{
        position: fixed !important;
        width: 570px !important;
        height: 570px !important;
        left: -90px !important;
        top:200px !important;



    }

}
.generate{
    position: relative;
    top:380px;
    left: 750px;
    width: 250px;
    height: 250px;
    z-index: 2;
    
    }
    .nexton{
position: absolute;
top:100px;
left:100px;

    }
    @media  (max-width: 1000px) and (max-height:500px)  {
        .nexton{
            top:500px  !important;
            left:100px  !important;
            
                }
    }
    
    
    @media  (max-width: 1000px) and (max-height:500px)  {
    
        .generate{
            position: absolute !important;
            top:820px !important;
            left: 100px !important;
            width: 450px !important;
            height: 450px !important;
            z-index: 2 !important;
    
        }
    
    }
    .attempt{
        position: fixed ;
        top:200px;
        left: 650px ;
        width: 400px ;
        height:400px ;
        z-index: 2 ;
        
        }
        
        
        @media  (max-width: 1000px) and (max-height:500px)  {
        
            .attempt{
                position: fixed !important;
                width: 300px !important;
                height: 300px;
                left: 100px;
        
        
            }
        
        }
        .box 
        {
            position:fixed;  
            left:650px;
            top:320px;

            z-index: 3;
        }
        .box2
        {
            position:fixed;  
            top:250px;
            left: 750px;
           font-family: 30px;
            z-index: 3;
        }
        .box23
        {
            position:fixed;  
            top:150px;
            left: 750px;
           font-family: 30px;
            z-index: 3;
        }

        .calculator{

            display: flex;
             font-Size: 30px;
             align-Items: center;
             padding: 30px;
             user-Select: none;
            left: -10px;
             position: relative;
              top: 5px; 
        }
        @media  (max-width: 1000px)   {


        .calculator{

            display: flex !important;
             font-Size: 30px !important;
             align-Items: center !important;
             padding: 30px !important;
             user-Select: none !important;
            left: 10px !important;
             position: relative !important;
              top: 5px !important; 
        }}

        .dividing{

            position : absolute ;
            left :  800px; 
            top: 480px;


        }
        @media  (max-width: 1000px)   {

        .dividing{

            position : relative   !important;
            left :  175px  !important; 
            top :  -400px  !important; 


        }}
        .das{
            width: 150px;
             height: 150px;
             position:relative;
             top:-40px;
             left:-20px;
        }
        @media  (max-width: 1000px)   {
            .das{
                width: 100px!important;
                 height: 100px!important;
                 position:absolute!important;
                 left:-1px!important;
                 top:10px!important;

            }
        }
        .cris{
            position: absolute;
             width: 150px ;
             height: 150px;
              top: 430px;
              left: 885px;
        }
        @media  (max-width: 1000px)   {
        
            .cris{
                position: absolute;
                 width: 150px ;
                 height: 150px;
                  top: 330px !important;
            } 
        
            
        }
        .cris2{
            position: absolute;
             width: 150px ;
             height: 150px;
              top: 350px;
              left: 890px;
        }
        @media  (max-width: 1000px)   {
        
            .cris2{
                position: absolute;
                 width: 150px ;
                 height: 150px;
                 top: 300px!important;
                } 
        
            
        }
      
        
            
        