.profile-page-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
  }
  
  .profile-card-header {
    background-color: #007bff;
    color: white;
  }
  
  .user-icon {
    color: #6c757d;
  }
  
  .performance-card-header {
    background-color: #28a745;
    color: white;
  }
  
  .performance-graph {
    margin-top: 20px;
  }
  
  .subject-bar {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
  }
  
  .subject-bar span {
    flex: 1;
    text-align: right;
    margin-right: 10px;
  }
  
  .bar {
    flex: 5;
    height: 20px;
    background-color: #007bff;
    color: white;
    text-align: center;
    line-height: 20px;
    border-radius: 5px;
  }
  