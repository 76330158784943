.title {
    position: fixed;
    width: 600px;
    height: 600px;
    bottom: 50px;
    left: 500px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    backdrop-filter: blur(7px) saturate(180%);
    -webkit-backdrop-filter: blur(18px) saturate(180%);
    background-color: rgba(233, 244, 255, 0.74);
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 15px rgba(189, 213, 255, 0.5);
    padding: 20px;
}
.title2 {
  position: fixed;
  width: 600px;
  height: 600px;
  bottom: 50px;
  left: 500px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  backdrop-filter: blur(7px) saturate(180%);
  -webkit-backdrop-filter: blur(18px) saturate(180%);
  background-color: rgba(233, 244, 255, 0.74);
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 0px 15px rgba(189, 213, 255, 0.5);
  padding: 20px;
}
.title3 {
    position: fixed;
    width: 700px;
    height: 150px;
    bottom: 500px;
    left: 700px;
    display: flex;
    flex-direction: column;
    z-index: 2;
    backdrop-filter: blur(7px) saturate(180%);
    -webkit-backdrop-filter: blur(18px) saturate(180%);
    background-color: rgba(255, 250, 191, 0.74);
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 15px rgba(189, 213, 255, 0.5);
    padding: 20px;
}

.main-list, .sub-list {
    list-style: none;
    padding-left: 0;
}

.main-list li, .sub-list li {
    margin: 5px 0;
    display: flex;
    transition: transform 0.2s;
}

.main-list li:hover, .sub-list li:hover {
    transform: scale(1.1);
}
.circle {
    width: 20px;
    height: 20px;
    background-color: rgb(82, 128, 255);
    border: 1px solid rgb(20, 1, 82);
    border-radius: 50%;
    margin-right: 10px;
}
.circle2 {
    width: 20px;
    height: 20px;
    background-color: rgb(192, 24, 2);
    border: 1px solid rgb(69, 0, 0);
    border-radius: 50%;
    margin-right: 10px;
}
.circle3 {
    width: 20px;
    height: 20px;
    background-color: rgb(221, 255, 2);
    border: 1px solid rgb(0, 0, 0);
    border-radius: 50%;
    margin-right: 10px;
}
@keyframes anim1{

    0%{
      height:0px;
    }
    
    100%{
      height:600px;
    }
  }
  @keyframes anim2{

    0%{
      height:0px;
    }
    
    100%{
      height:600px;
    }
  }
  @keyframes anim3{

    0%{
      height:0px;
    }
    
    100%{
      height:600px;
    }
  }
  .custom-cursor {
    cursor: url('pen.png') 10 10, auto;
  }
  