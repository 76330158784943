.hamburger-btn {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: #333;
    color: #fff;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
  }
  
  .side-dashboard {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    background-color: #2a61eb;
    color: #fff;
    transition: width 0.3s ease;
    z-index: 999;
    font-family:  Helvetica, 
  }
  .side-dashboard2 {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(to bottom, #2C2575, #91306D); /* Your specified gradient */
    color: #fff;
    transition: width 0.3s ease;
    z-index: 999;
    font-family:  Helvetica, 
   
  }
  .side-dashboard3 {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow: hidden;
  background: linear-gradient(to bottom, #2C2575, #91306D); /* Your specified gradient */
    color: #fff;
    transition: width 0.3s ease;
    z-index: 999;
    font-family:  Helvetica, 
   
  }
  .dashboard-links {
    padding: 20px;
    font-family:  Helvetica; 

  }
  
  .dashboard-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family:  Helvetica; 

  }
  
  .dashboard-links li {
    margin-bottom: 10px;
    font-family:  Helvetica;

  }
  
  .dashboard-links a {
    color: #fff;
    text-decoration: none;
    font-family:  Helvetica, 

  }

  .highlighted-question {
    background-color: yellow; /* or any color you prefer */
    font-weight: bold;
  }
  