.main{
  position:fixed  !important;

}
@media  (max-width: 1000px) and (max-height:500px)  {
  .main{
    position:absolute  !important;
  
  }
}


.questionsmainpage{
  max-width: 100vw ;
  max-height: 97vh;
  overflow:hidden;
}
.headingquestions{
 position:fixed;
 top:120px;
 left:500px;
 width: 400px;
 height: 400px;
}
@media  (max-width: 1000px) and (max-height:500px)  {
  .questionsmainpage{
    max-width: 100vw !important;
    max-height: 100vh !important;
    overflow:hidden !important;
  }
}



  
@media  (max-width: 1000px) and (max-height:500px) {
  body {
    overflow: hidden !important;

    background-color: #fff; /* Change background color for landscape */
   
  }
  /* Add your smartphone landscape styles here */
  .App {
    max-width: 500vw;
    height: 200vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media  (max-width: 1000px)  {
  .introbox{
    position: fixed !important;
    justify-content: center;

    width: 1100px !important;
    height: 1100px !important;
    bottom:-380px  !important;
    left: 5px !important;
    overflow: hidden;
    align-content: center;
    align-items: center;

  }
 
}
@media  (max-width: 1000px) and (max-height:500px) {

.cont1{

}}

@media  (max-width: 1000px) and (max-height:500px) {

  .welcom {

    position: fixed !important;
    width: 500px !important;
    height: 220px !important;
    justify-content: center; 
    bottom: 25px!important;
    left: 180px !important;
    display: flex !important;
    z-index: 2 !important;
    animation: sizeAnimation 1s linear!important;
    backdrop-filter: blur(7px) saturate(180%) !important;
    -webkit-backdrop-filter: blur(18px) saturate(180%) !important;
    background-color: rgba(228, 229, 230, 0.74) !important;
    border-radius: 12px !important;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6) !important;
  }

}

.welcom {

  position: relative;
  width: 800px;
  height: 400px;
  bottom: -150px;
  left: 400px;
  display: flex;
  z-index: 2; /* Fix the typo here */
  animation: sizeAnimation 1s linear;
  backdrop-filter: blur(7px) saturate(180%);
  -webkit-backdrop-filter: blur(18px) saturate(180%);
  background-color: rgba(51, 153, 255, 0.74);
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);

}

.welcommob {
  position: absolute;
  width: 300px;
  height: 500px;
  top:50px;
  left:50px;
  right:50%;
  bottom: 50%;


  z-index: 2;
  animation: sizeAnimation 1s linear;
  backdrop-filter: blur(7px) saturate(180%);
  -webkit-backdrop-filter: blur(18px) saturate(180%);
  background-color: rgba(51, 153, 255, 0.74);
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);
}


#clone-container {
  position: relative; /* Container should have relative positioning */
}

.introbox {
  position: fixed;
  width: 1380px;
  height: 1380px;
  bottom:-650px;
  left: 50px;
  
};

.introboxmob {
  position: fixed;
  width: 1280px;
  height: 1280px;
  bottom:-550px;
  left: 150px;
  
};
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
    overflow: hidden !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
@media  (max-width: 1000px)  {
.welcomewords{

  position: relative;
  width: 200px !important;
  height: 200px !important;
  bottom: -10px !important;
  left: 0px !important;
  zIndex: 2 !important;
}
}

.welcomewords{

  position: relative;
  width: 330px;
  height: 330px;
  bottom: -25px;
  left: 240px;
  zIndex: 2;
}

.startbutton{
  position: fixed;
  height: 330px;
  bottom: -305px;
  left: -10px;
  zIndex: 2;

}
@media  (max-width: 1000px) and (max-height:500px) {

.startbutton{
  position: absolute !important;
  height: 100px  !important;
  width: 100px  !important;

  bottom:-45px  !important;
  left: 190px  !important;
  zIndex: 2 

}
}

.interactive{
  position: absolute;
  width: 880px;
  height: 500px;
  bottom: 180px;
  left: 340px;
  display: flex;
  z-index: 2; /* Changed zindex to z-index */
  animation: sz3 1.2s linear;
  backdrop-filter: blur(7px) saturate(180%);
  -webkit-backdrop-filter: blur(18px) saturate(180%);
  background-color: rgba(189, 222, 255, 0.74);
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);
}

@media  (max-width: 1000px) and (max-height:500px) {
  .interactive{
 

    position: fixed !important;
    height: 350px !important;
    width: 600px !important;
    justify-self: center !important;
    bottom: 20px!important;
    left: -410px !important;
    display: flex !important;
    z-index: 2 !important; /* Changed zindex to z-index */
    margin-left: 550px; /* Changed marginLeft to margin-left */
    margin-right: 500px; /* Changed marginRight to margin-right */
    animation: sz2 1.5s linear;
    backdrop-filter: blur(7px) saturate(180%);
    -webkit-backdrop-filter: blur(18px) saturate(180%);
    background-color: rgba(189, 222, 255, 0.74);
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);

  }

}

.boxes3{
  position: relative;
  width: 1100px;  
  height: 500px;
  bottom: -100px;
  left: -450px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:10px;
  z-index: 2;
 
}


@media  (max-width: 1000px) and (max-height:500px) {

  .boxes3{
    position: fixed  !important;
    width: 10px !important; 
    height: 10px !important;
    bottom: 180px !important;
    left: 450px !important;
    display: flex !important;
    margin:10px !important;
    z-index: 2 !important;
   
  }

}




.clicker{
  position: absolute;
  width: 800px;
  height: 800px;
  top:250px;
  left: 0px;
}
@media  (max-width: 1000px) and (max-height:500px) {
  .clicker{
    position: absolute !important;
    width: 500px !important;
    height: 500px !important;
    top:150px !important;
    left: 0px !important;
  }
}

.learnhow{
  position: relative;
  width: 400px;
  height: 400px;
  bottom: -20px;
  left: 245px;
  display: flex;
  z-index: 2
}

@media  (max-width: 1000px) and (max-height:500px) {
  .learnhow{
    position: absolute !important;
    width: 150px !important;
    height: 150px !important;
    bottom: 130px !important;
    left: 220px !important;
    display: flex !important;
    z-index: 2 !important;

  }
}

@media  (max-width: 1000px) and (max-height:500px) {
.nextbutton2{

  position: fixed !important;
  width: 330px !important;
  height: 330px !important;
  bottom: 500px !important;
  left: 50px !important;
  z-Index: 2 !important;
}
}

.nextbutton2{

  position: absolute; 
  height: 2330px ;
  width: 2330px ;
  top: 100px;
  left: 100px;
  z-Index: 2 ;
}

.nextbutton3{

  position: relative; 
  height: 330px ;
  width: 330px ;
  top:100px;
  left:800px;
 
}

.trial2{
  position: relative; 
  height: 330px ;
  width: 330px ;
  left: 350px;
  top:100px;
}
@media  (max-width: 1000px) and (max-height:500px) {
  .trial2{
    position: relative; 
    height: 300px  !important;
    width: 300px  !important;
    left:400px  !important;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.test1{

  box-shadow: 0px 0px 18px rgba(97, 59, 27, 0.2);
  
}
.test2{
  text-shadow: 2px 2px 4px rgba(0,0,0.0.2) ;
  box-shadow: 0px 7px 8px rgba(84, 65, 49, 0.3) inset;
  box-shadow: 0px 7px 8px rgba(141, 98, 64, 0.3) ;


}
.card{
backdrop-filter: blur(5px) saturate(180%);
-webkit-backdrop-filter: blur(18px) saturate(180%);
background-color: rgba(230, 228, 228, 0.74);
border-radius: 12px;
border: 1px solid rgba(0, 0, 0, 0.125);
box-shadow: 0px 0px 10px rgba(94, 94, 94, 0.6);

}
.card2{
  animation: sizeAnimation 1s linear ;

  backdrop-filter: blur(7px) saturate(180%);
  -webkit-backdrop-filter: blur(18px) saturate(180%);
  background-color: rgba(228, 229, 230, 0.74);
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);
  }

  .card3{
    animation: sz2 1s linear ;
  
    backdrop-filter: blur(7px) saturate(180%);
    -webkit-backdrop-filter: blur(18px) saturate(180%);
    background-color: rgba(189, 222, 255, 0.74);
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);
    }

    .learnbox{
    
      backdrop-filter: blur(7px) saturate(180%);
      -webkit-backdrop-filter: blur(18px) saturate(180%);
      background-color: rgba(200, 218, 251, 0.74);
      border-radius: 30px;
      border: 1px solid rgba(0, 0, 0, 0.125);
      box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);
      transition: transform 0.5s linear;

      }
      .learnbox:hover{
        transform: scale(1.2);
      }

      .questionsbox{
    
        backdrop-filter: blur(7px) saturate(180%);
        -webkit-backdrop-filter: blur(18px) saturate(180%);
        background-color: rgba(200, 218, 251, 0.74);
        border-radius: 30px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);
        transition: transform 0.5s linear;

        }
        .questionsbox:hover{
          transform: scale(1.2);
        }
        .topicsbox{
    
          backdrop-filter: blur(7px) saturate(180%);
          -webkit-backdrop-filter: blur(18px) saturate(180%);
          background-color: rgba(189, 222, 255, 0.74);
          border-radius: 30px;
          border: 1px solid rgba(0, 0, 0, 0.125);
          box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);
          transition: transform 0.5s linear;

          }
          .topicsbox:hover{
            transform: scale(1.2);
          }

    .tiles1{
      animation: tilesa 5s ease out ;
    
      }


  .shows{
    backdrop-filter: blur(7px) saturate(180%);
    -webkit-backdrop-filter: blur(18px) saturate(180%);
    background-color: rgba(140, 82, 82, 0.74);
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 15px rgba(94, 94, 94, 0.6);
  }

.neo{
  border-radius: 30px;
background: #e0e0e0;
box-shadow: 20px -20px 60px #d9d9d9,
            -20px 20px 60px #ffffff;
}
.trans{
  animation: sizeAnimation 0.5s ease-in ;
  border-radius: 30px;
background: #e0e0e0;
box-shadow: 20px -20px 60px #bebebe,
            -20px 20px 60px #ffffff;
}
@keyframes sizeAnimation{

  0%{
    height:0px;
  }
  
  100%{
    height:400px;
  }
}
@keyframes sz2{

  0%{
    height:0px;
  }
  
  100%{
    height:350px;
  }
}
@keyframes sz3{

  0%{
    height:0px;
  }
  
  100%{
    height:550px;
  }
}

@keyframes tilesa{

  0%{
    height:0px;
    width: 0px;
  }
  
 
  100%{
    height:200px;
    width: 200px;

  }
}


.transition2{
  box-shadow: 0px 7px 8px rgba(141, 98, 64, 0.3) ;

  transition: transform 0.5s linear;

}
.transition2:hover{
  transform: scale(1.2);
}

@media  (max-width: 1000px) and (max-height:500px) {

  .dividing{
    position: absolute !important;
    top: 230px !important;
    left: 430px !important;

  }
}

.dividing{
position:absolute;
top:420px;

}



.upperanimation{
  position: fixed;
  width: 300px;
  height: 300px;
  top: 200px;
  left:800px;
  
  }
  @media  (max-width: 1000px) and (max-height:500px)  {
      .upperanimation{
          position: fixed  !important;
          width: 250px  !important;
          height: 250px  !important;
          top: 35px  !important;
          left:350px  !important;
          
          }
  
  }
  .error-message {
    color: rgb(125, 0, 0);
    font-weight: bold;
    margin-top: 10px;
    width: 400px;
    height: 100px;
  }
  .login {
    position: fixed;
    width: 400px;
    height: 400px;
    align-self: center;
    display: flex;
    flex-direction: column;
    z-index: 2;
    backdrop-filter: blur(7px) saturate(180%);
    -webkit-backdrop-filter: blur(18px) saturate(180%);
    background-color: rgba(233, 244, 255, 0.74);
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    box-shadow: 0px 0px 15px rgba(189, 213, 255, 0.5);
    padding: 20px;
}
.signup {
  position: fixed;
  width: 350px;
  top:200px;
  height: 300px;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 2;
  backdrop-filter: blur(7px) saturate(180%);
  -webkit-backdrop-filter: blur(18px) saturate(180%);
  background-color: rgba(145, 174, 240, 0.74);
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 0px 15px rgba(189, 213, 255, 0.5);
  padding: 20px;
}
.login2 {
  position: fixed;
  width: 300px;
  height: 100px;
  top:370px;

  fill: none;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 2;
  backdrop-filter: blur(7px) saturate(180%);
  -webkit-backdrop-filter: blur(18px) saturate(180%);

  border-radius: 12px;
}