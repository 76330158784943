.side-dashboard {
    background-color: rgb(37, 2, 79);
    position: fixed; /* Fix the position */
    top: 0; /* Position at the top of the viewport */
    bottom: 0; /* Extend to the bottom of the viewport */
    left: 0; /* Position at the left side */
    width: 200px;
    transition: width 1.0s ease; /* Transition for smooth width change */

  }
  
  
/* Adjust other elements to appear on the right of the dashboard */
.dashboard-container {
    display: flex;
  }
  
  .other {
    flex: 1;
    padding-left: 220px; /* Adjust padding to leave space for the dashboard */
  }

  .dashboard-links a {
    color: rgb(255, 236, 236); /* White text */
    text-decoration: none; /* No underline */
    display: block;
    top: 100px;
    margin-bottom: 18px; /* Add margin between rows */
  
    padding: 10px; /* Add padding for better clickability */
    
  }
  .dashboard-links ul {
    list-style-type: none; /* Remove dots/pointers/markers */
    padding: 0;
  }
  
  .dashboard-links a:hover {
    background-color: #010000; /* Grey hover background */
    border-radius: 5px; /* Rounded corners */
  }

  .texts{
    flex-grow: 1;
    padding-left: 420px; /* Adjust padding to leave space for the dashboard */

  }

  .plus {
    animation: plus;
    animation-duration: 4.8s;
    animation-iteration-count: infinite;
    animation-name: electron2animation;
    animation-timing-function: ease;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg) translateX(90px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(90px) rotate(-360deg);
    }
  }
  @keyframes moveX {
    from {
      transform:  translateX(0) ;
    }
    to {
      transform:  translateX(100%) ;
    }
  }

  
    .upper{
      color: aliceblue;

      
      
      
    }
    .lower{
      color: aliceblue;
box-shadow:  -3px -3px 7px #ffffffb2,
  3px 3px 7px rgba(94, 104, 121, 0.945);
   
      
    }