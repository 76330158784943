.slick-carousel-container {
    max-width: 500px; /* Set the maximum width for the carousel */
    margin: 0 auto; /* Center the carousel */
  }
  .header {
    background-color: rgba(62, 63, 78, 0.8); /* Transparent white with slight opacity */
    border-bottom: 1px solid #140a07; /* Custom stroke color */
    backdrop-filter: blur(3px); /* Blur effect */
    -webkit-backdrop-filter: blur(3px); /* For Safari support */
  }
  
    body {
      font-family: 'Montserrat', Helvetica, sans-serif; /* Google Font with fallbacks */
    }
     /* Add borders between navbar links */
.navbar-nav .nav-link {
  border-right: 1px solid white; /* Adds a white border to the right */
  padding: 10 25px; /* Space around text for readability */
  text-align: center; /* Centers text */
}

/* Remove the border for the last link */
.navbar-nav .nav-link:last-child {
  border-right: none;
}

/* Initial opacity set to 20% for elements out of view */
.fade-opacity {
  opacity: 0.2;
  transition: opacity 0.5s ease-in-out;
}

/* Apply 100% opacity when the element comes into view */
.fade-opacity.visible {
  opacity: 1;
}
/* Add this in your CSS file */
.move-left {
  animation: moveLeft 0.6s forwards;
}

@keyframes moveLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100px); /* Move 100px to the left */
  }
}
