.black-square {
    width: 100px;
    height: 100px;
    background-color:  black;
    position: absolute;
    top: 0;
    cursor: grab;
  }
  
  .black-square2 {
    width: 100px;
    height: 100px;
    position: absolute;
    color: bisque;
    top: 0;
    cursor: grab;
  }
  .horizontal-line {
   
    background-color: black;
  }
  