.circular-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #008000;
    border: 2px solid #fff;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .circular-button:hover {
    background-color: #fff;

    border-color: #008000;
  }
  
 
.arrow-next {
    
    font-size: 35px;
    color:  #fff;
    text-shadow: 0 0 2px #fff;
    transition: color 0.3s, text-shadow 0.3s;
    margin-bottom: 5px; /* add this */
  }
  
  
  
  .arrow-next::after {
    content: '→';
    color: #fff;
    text-shadow: 0 0 2px #008000;
  }
  
  .circular-button:hover .arrow-next {
    color: #008000;
    text-shadow: 0 0 2px #008000;
  }
  
  .circular-button:hover .arrow-next::after {
    color: #008000;
    text-shadow: 0 0 2px #008000;
  }
  




  .circular-button-back {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bd060f;
    border: 2px solid #fff;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .circular-button-back:hover {
    background-color: #fff;

    border-color: #bd060f;
  }
  
 
.arrow-back {
    
    font-size: 35px;
    color:  #fff;
    text-shadow: 0 0 2px #ffffff;
    transition: color 0.3s, text-shadow 0.3s;
    margin-top: 8px; /* add this */
    transform: rotate(180deg);
  }
  
  
  
  .arrow-back::after {
    content: '→';
    color: #fff;
    text-shadow: 0 0 2px #bd060f;
  }
  
  .circular-button-back:hover .arrow-next {
    color: #bd060f;
    text-shadow: 0 0 2px #bd060f;
  }
  
  .circular-button-back:hover .arrow-next::after {
    color: #bd060f;
    text-shadow: 0 0 2px #bd060f;
  }
  
