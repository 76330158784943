
.scenariobody {
    background: #b2e0ff;
    overflow: hidden !important;
  }
  

.mobile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    top:35px;
    position: relative;
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(23, 96, 242);
    color: #ffffff;
    user-select: none;
    font-family: helvetica;
    font-weight: bolder;
    font-size: 10px;
    width: 100%;
    padding: 10px 0;
    position: absolute;
    top: 0;
}